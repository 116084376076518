import { fetch } from '@/utils/request';

// 分类
const solutionMenuIndex = (param, callback) => fetch('/admin/1.0/solution/menu/index','GET', param, callback);
const solutionMenuInfo = (param, callback) => fetch('/admin/1.0/solution/menu/detail','GET', param, callback);
const solutionMenuEdit = (param, callback) => fetch('/admin/1.0/solution/menu/edit','POST', param, callback);
const solutionMenuDel = (param, callback) => fetch('/admin/1.0/solution/menu/del','POST', param, callback);

// 列表
const solutionIndex = (param, callback) => fetch('/admin/1.0/solution/index','GET', param, callback);
const solutionInfo = (param, callback) => fetch('/admin/1.0/solution/detail','GET', param, callback);
const solutionEdit = (param, callback) => fetch('/admin/1.0/solution/edit','POST', param, callback);
const solutionDel = (param, callback) => fetch('/admin/1.0/solution/del','POST', param, callback);

export default {
  solutionMenuIndex,
  solutionMenuInfo,
  solutionMenuEdit,
  solutionMenuDel,

  solutionIndex,
  solutionInfo,
  solutionEdit,
  solutionDel

}
