import layout from '../view/layout'

const router = {
  name: 'about',
  path: 'about',
  component: layout,
  meta: {
    title: '关于动联',
    ico: 'el-icon-document',
    authkey: 'about'
  },
  children: [
    {
      name: 'about_contact',
      path: 'contact',
      component: () => import('../view/about/contact_index'),
      meta: {
        route_name: 'about_contact',
        title: '联系我们',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'contact_us'
      }
    },
    {
      name: 'about_contact_edit',
      path: 'contact/edit',
      component: () => import('../view/about/contact_add'),
      meta: {
        route_name: 'about_contact',
        title: '联系我们编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'contact_us'
      }
    },
    {
      name: 'about_culture',
      path: 'culture',
      component: () => import('../view/about/culture_index'),
      meta: {
        route_name: 'about_culture',
        title: '荣誉资质',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_culture'
      }
    },
    {
      name: 'about_culture_edit',
      path: 'culture/edit',
      component: () => import('../view/about/culture_add'),
      meta: {
        route_name: 'about_culture',
        title: '荣誉资质编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'about_culture'
      }
    },
    {
      name: 'about_history',
      path: 'history',
      component: () => import('../view/about/history_index'),
      meta: {
        route_name: 'about_history',
        title: '发展历程',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_history'
      }
    },
    {
      name: 'about_history_edit',
      path: 'history/edit',
      component: () => import('../view/about/history_add'),
      meta: {
        route_name: 'about_history',
        title: '发展历程编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'about_history'
      }
    },
    {
      name: 'about_honor',
      path: 'honor',
      component: () => import('../view/about/honor_index'),
      meta: {
        route_name: 'about_honor',
        title: '企业文化',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_honor'
      }
    },
    {
      name: 'about_honor_edit',
      path: 'honor/edit',
      component: () => import('../view/about/honor_add'),
      meta: {
        route_name: 'about_honor',
        title: '企业文化编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'about_honor'
      }
    },
    {
      name: 'about_intro',
      path: 'intro',
      component: () => import('../view/about/intro'),
      meta: {
        route_name: 'about_intro',
        title: '动联简介',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_intro'
      }
    },
    {
      name: 'about_job',
      path: 'job',
      component: () => import('../view/about/job_index'),
      meta: {
        route_name: 'about_job',
        title: '招贤纳士',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_job'
      }
    },
    {
      name: 'about_job_edit',
      path: 'job/edit',
      component: () => import('../view/about/job_add'),
      meta: {
        route_name: 'about_job',
        title: '招贤纳士编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'about_job'
      }
    },
    {
      name: 'about_service',
      path: 'service',
      component: () => import('../view/about/service_index'),
      meta: {
        route_name: 'about_service',
        title: '专业服务',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_service'
      }
    },
    {
      name: 'about_service_edit',
      path: 'service/edit',
      component: () => import('../view/about/service_add'),
      meta: {
        route_name: 'about_service',
        title: '专业服务编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'about_service'
      }
    },
    {
      name: 'about_park',
      path: 'park',
      component: () => import('../view/about/park_index'),
      meta: {
        route_name: 'about_park',
        title: '生产园区',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'about_park'
      }
    },
    {
      name: 'about_park_edit',
      path: 'park/edit',
      component: () => import('../view/about/park_add'),
      meta: {
        route_name: 'about_park',
        title: '生产园区编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'about_spark'
      }
    },
  ]
}

export default router
