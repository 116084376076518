import { fetch } from '@/utils/request';

// 分类
const goodsMenuIndex = (param, callback) => fetch('/admin/1.0/goods/menu/index','GET', param, callback);
const goodsMenuInfo = (param, callback) => fetch('/admin/1.0/goods/menu/detail','GET', param, callback);
const goodsMenuEdit = (param, callback) => fetch('/admin/1.0/goods/menu/edit','POST', param, callback);
const goodsMenuDel = (param, callback) => fetch('/admin/1.0/goods/menu/del','POST', param, callback);

// 产品
const goodsIndex = (param, callback) => fetch('/admin/1.0/goods/index','GET', param, callback);
const goodsInfo = (param, callback) => fetch('/admin/1.0/goods/detail','GET', param, callback);
const goodsEdit = (param, callback) => fetch('/admin/1.0/goods/edit','POST', param, callback);
const goodsDel = (param, callback) => fetch('/admin/1.0/goods/del','POST', param, callback);
const goodsRec = (param, callback) => fetch('/admin/1.0/goods/rec','POST', param, callback);

// 产品展示
const goodsShowIndex = (param, callback) => fetch('/admin/1.0/goods/show/index','GET', param, callback);
const goodsShowInfo = (param, callback) => fetch('/admin/1.0/goods/show/detail','GET', param, callback);
const goodsShowEdit = (param, callback) => fetch('/admin/1.0/goods/show/edit','POST', param, callback);
const goodsShowDel = (param, callback) => fetch('/admin/1.0/goods/show/del','POST', param, callback);

export default {
  goodsMenuIndex,
  goodsMenuInfo,
  goodsMenuEdit,
  goodsMenuDel,

  goodsIndex,
  goodsInfo,
  goodsEdit,
  goodsDel,
  goodsRec,

  goodsShowIndex,
  goodsShowInfo,
  goodsShowEdit,
  goodsShowDel
}
