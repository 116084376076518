import layout from '../view/layout'

const router = {
  name: 'goods',
  path: 'goods',
  component: layout,
  meta: {
    title: '产品',
    ico: 'el-icon-document',
    authkey: 'goods'
  },
  children: [
    {
      name: 'goods_index',
      path: 'index',
      component: () => import('../view/goods/index'),
      meta: {
        route_name: 'goods_index',
        title: '产品列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'goods_index'
      }
    },
    {
      name: 'goods_edit',
      path: 'edit',
      component: () => import('../view/goods/add'),
      meta: {
        route_name: 'goods_index',
        title: '产品编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'goods_index'
      }
    },
    {
      name: 'goods_menu',
      path: 'menu',
      component: () => import('../view/goods/menu'),
      meta: {
        route_name: 'goods_menu',
        title: '产品分类',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'goods_menu'
      }
    },
    {
      name: 'goods_menu_edit',
      path: 'menu/edit',
      component: () => import('../view/goods/menu_add'),
      meta: {
        route_name: 'goods_menu',
        title: '分类编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'goods_menu'
      }
    },
    {
      name: 'goods_show',
      path: 'show',
      component: () => import('../view/goods/show'),
      meta: {
        route_name: 'goods_show',
        title: '产品展示',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'goods_show'
      }
    },
    {
      name: 'goods_show_edit',
      path: 'show/edit',
      component: () => import('../view/goods/show_add'),
      meta: {
        route_name: 'goods_show',
        title: '展示编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'goods_show'
      }
    },
  ]
}

export default router
