import config from '../config/config'

let CACHE_TOKEN = config.CACHE_TOKEN
let SYS_VERSION = config.SYS_VERSION

// token
const getToken = ()=> window.localStorage.getItem(CACHE_TOKEN)
const setToken = (token) => window.localStorage.setItem(CACHE_TOKEN, token)
const removeToken =()=> window.localStorage.removeItem(CACHE_TOKEN)

// version
const getVersion = ()=> window.localStorage.getItem(SYS_VERSION)
const setVersion = (version) => window.localStorage.setItem(SYS_VERSION, version)

export {
    getToken,
    setToken,
    removeToken,

    getVersion,
    setVersion
}
