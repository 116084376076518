import layout from '../view/layout'

const router = {
  name: 'solution',
  path: 'solution',
  component: layout,
  meta: {
    title: '解决方案',
    ico: 'el-icon-document',
    authkey: 'solution'
  },
  children: [
    {
      name: 'solution_index',
      path: 'index',
      component: () => import('../view/solution/index'),
      meta: {
        route_name: 'solution_index',
        title: '方案列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'solution_index'
      }
    },
    {
      name: 'solution_edit',
      path: 'edit',
      component: () => import('../view/solution/add'),
      meta: {
        route_name: 'solution_index',
        title: '方案编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'solution_index'
      }
    },
    // {
    //   name: 'solution_menu',
    //   path: 'menu',
    //   component: () => import('../view/solution/menu'),
    //   meta: {
    //     route_name: 'solution_menu',
    //     title: '方案分类',
    //     ico: 'el-icon-c-scale-to-original',
    //     isShow: true,
    //     authkey: 'solution_menu'
    //   }
    // },
  ]
}

export default router
