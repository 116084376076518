import merchant from './merchant'
import news from './news'
import goods from './goods'
import solution from './solution'
import about from './about'

export default {
  merchant,
  news,
  goods,
  solution,
  about
}
