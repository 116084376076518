import { fetch } from '@/utils/request';

// 分类
const newsMenuIndex = (param, callback) => fetch('/admin/1.0/news/menu/index','GET', param, callback);
const newsMenuInfo = (param, callback) => fetch('/admin/1.0/news/menu/detail','GET', param, callback);
const newsMenuEdit = (param, callback) => fetch('/admin/1.0/news/menu/edit','POST', param, callback);
const newsMenuDel = (param, callback) => fetch('/admin/1.0/news/menu/del','POST', param, callback);

// 新闻
const newsIndex = (param, callback) => fetch('/admin/1.0/news/index','GET', param, callback);
const newsInfo = (param, callback) => fetch('/admin/1.0/news/detail','GET', param, callback);
const newsEdit = (param, callback) => fetch('/admin/1.0/news/edit','POST', param, callback);
const newsDel = (param, callback) => fetch('/admin/1.0/news/del','POST', param, callback);

// 新闻
const newsDateInfo = (param, callback) => fetch('/admin/1.0/news/date/info','GET', param, callback);
const newsDateSave = (param, callback) => fetch('/admin/1.0/news/date/save','POST', param, callback);

// 展会
const newsExhibitionIndex = (param, callback) => fetch('/admin/1.0/exhibition/index','GET', param, callback);
const newsExhibitionInfo = (param, callback) => fetch('/admin/1.0/exhibition/detail','GET', param, callback);
const newsExhibitionEdit = (param, callback) => fetch('/admin/1.0/exhibition/edit','POST', param, callback);
const newsExhibitionDel = (param, callback) => fetch('/admin/1.0/exhibition/del','POST', param, callback);

export default {
  newsMenuIndex,
  newsMenuInfo,
  newsMenuEdit,
  newsMenuDel,

  newsIndex,
  newsInfo,
  newsEdit,
  newsDel,

  newsDateInfo,
  newsDateSave,

  newsExhibitionIndex,
  newsExhibitionInfo,
  newsExhibitionEdit,
  newsExhibitionDel

}
