import layout from '../view/layout'

const router = {
  name: 'news',
  path: 'news',
  component: layout,
  meta: {
    title: '新闻',
    ico: 'el-icon-document',
    authkey: 'news'
  },
  children: [
    {
      name: 'news_index',
      path: 'index',
      component: () => import('../view/news/index'),
      meta: {
        route_name: 'news_index',
        title: '新闻列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'news_index'
      }
    },
    {
      name: 'news_edit',
      path: 'edit',
      component: () => import('../view/news/add'),
      meta: {
        route_name: 'news_index',
        title: '新闻编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'news_index'
      }
    },
    {
      name: 'news_menu',
      path: 'menu',
      component: () => import('../view/news/menu'),
      meta: {
        route_name: 'news_menu',
        title: '新闻分类',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'news_menu'
      }
    },
    {
      name: 'news_date',
      path: 'date',
      component: () => import('../view/news/date'),
      meta: {
        route_name: 'news_date',
        title: '新闻日期',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'news_date'
      }
    },
    {
      name: 'news_exhibition',
      path: 'exhibition',
      component: () => import('../view/news/exhibition'),
      meta: {
        route_name: 'news_exhibition',
        title: '展会管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'news_exhibition'
      }
    },
    {
      name: 'exhibition_edit',
      path: 'exhibition/edit',
      component: () => import('../view/news/exhibition_add'),
      meta: {
        route_name: 'news_exhibition',
        title: '展会编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'news_exhibition'
      }
    },
  ]
}

export default router
