import { fetch } from '@/utils/request';

// 联系我们
const aboutContactIndex = (param, callback) => fetch('/admin/1.0/about/contact/index','GET', param, callback);
const aboutContactInfo = (param, callback) => fetch('/admin/1.0/about/contact/detail','GET', param, callback);
const aboutContactEdit = (param, callback) => fetch('/admin/1.0/about/contact/edit','POST', param, callback);
const aboutContactDel = (param, callback) => fetch('/admin/1.0/about/contact/del','POST', param, callback);

// 荣誉资质
const aboutCultureIndex = (param, callback) => fetch('/admin/1.0/about/culture/index','GET', param, callback);
const aboutCultureInfo = (param, callback) => fetch('/admin/1.0/about/culture/detail','GET', param, callback);
const aboutCultureEdit = (param, callback) => fetch('/admin/1.0/about/culture/edit','POST', param, callback);
const aboutCultureDel = (param, callback) => fetch('/admin/1.0/about/culture/del','POST', param, callback);

// 发展历程
const aboutHistoryIndex = (param, callback) => fetch('/admin/1.0/about/history/index','GET', param, callback);
const aboutHistoryInfo = (param, callback) => fetch('/admin/1.0/about/history/detail','GET', param, callback);
const aboutHistoryEdit = (param, callback) => fetch('/admin/1.0/about/history/edit','POST', param, callback);
const aboutHistoryDel = (param, callback) => fetch('/admin/1.0/about/history/del','POST', param, callback);

// 企业文化
const aboutHonorIndex = (param, callback) => fetch('/admin/1.0/about/honor/index','GET', param, callback);
const aboutHonorInfo = (param, callback) => fetch('/admin/1.0/about/honor/detail','GET', param, callback);
const aboutHonorEdit = (param, callback) => fetch('/admin/1.0/about/honor/edit','POST', param, callback);
const aboutHonorDel = (param, callback) => fetch('/admin/1.0/about/honor/del','POST', param, callback);

// 动联简介
const aboutIntroInfo = (param, callback) => fetch('/admin/1.0/about/intro/detail','GET', param, callback);
const aboutIntroEdit = (param, callback) => fetch('/admin/1.0/about/intro/edit','POST', param, callback);

// 招贤纳士
const aboutJobIndex = (param, callback) => fetch('/admin/1.0/about/job/index','GET', param, callback);
const aboutJobInfo = (param, callback) => fetch('/admin/1.0/about/job/detail','GET', param, callback);
const aboutJobEdit = (param, callback) => fetch('/admin/1.0/about/job/edit','POST', param, callback);
const aboutJobDel = (param, callback) => fetch('/admin/1.0/about/job/del','POST', param, callback);

// 专业服务
const aboutServiceIndex = (param, callback) => fetch('/admin/1.0/about/service/index','GET', param, callback);
const aboutServiceInfo = (param, callback) => fetch('/admin/1.0/about/service/detail','GET', param, callback);
const aboutServiceEdit = (param, callback) => fetch('/admin/1.0/about/service/edit','POST', param, callback);
const aboutServiceDel = (param, callback) => fetch('/admin/1.0/about/service/del','POST', param, callback);

// 专业服务
const aboutParkIndex = (param, callback) => fetch('/admin/1.0/about/park/index','GET', param, callback);
const aboutParkInfo = (param, callback) => fetch('/admin/1.0/about/park/detail','GET', param, callback);
const aboutParkEdit = (param, callback) => fetch('/admin/1.0/about/park/edit','POST', param, callback);
const aboutParkDel = (param, callback) => fetch('/admin/1.0/about/park/del','POST', param, callback);

export default {
  aboutParkIndex,
  aboutParkInfo,
  aboutParkEdit,
  aboutParkDel,

  aboutContactIndex,
  aboutContactInfo,
  aboutContactEdit,
  aboutContactDel,

  aboutCultureIndex,
  aboutCultureInfo,
  aboutCultureEdit,
  aboutCultureDel,

  aboutHistoryIndex,
  aboutHistoryInfo,
  aboutHistoryEdit,
  aboutHistoryDel,

  aboutHonorIndex,
  aboutHonorInfo,
  aboutHonorEdit,
  aboutHonorDel,

  aboutIntroInfo,
  aboutIntroEdit,

  aboutJobIndex,
  aboutJobInfo,
  aboutJobEdit,
  aboutJobDel,

  aboutServiceIndex,
  aboutServiceInfo,
  aboutServiceEdit,
  aboutServiceDel

}
