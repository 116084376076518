import layout from '../view/layout'

const router = {
  name: 'link',
  path: 'link',
  component: layout,
  meta: {
    title: '成功案例',
    ico: 'el-icon-document',
    authkey: 'link'
  },
  children: [
    {
      name: 'link_index',
      path: 'index',
      component: () => import('../view/link/index'),
      meta: {
        route_name: 'link_index',
        title: '案例列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'link_index'
      }
    },
    {
      name: 'link_edit',
      path: 'edit',
      component: () => import('../view/link/add'),
      meta: {
        route_name: 'link_index',
        title: '案例编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'link_index'
      }
    },
    {
      name: 'link_menu',
      path: 'menu',
      component: () => import('../view/link/menu'),
      meta: {
        route_name: 'link_menu',
        title: '案例分类',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'link_menu'
      }
    },
  ]
}

export default router
